// ------------------------------
// textsfromjoe.com layouts

// ------------------------------
// #GENERAL
// ------------------------------
.wrapper--view {
    padding: spacing(x-large) spacing(large);

    @include susy-breakpoint($bp-medium, $susy) {
        padding: spacing(xx-large) spacing(x-large);
    }
}

.wrapper--grid {
    @include container;
}

.header--site {
    text-align: center;

    @include susy-breakpoint($bp-large, $susy) {
        text-align: left;
    }
}

.header--site__title__prefix {
    display: block;
    margin-bottom: spacing(base);

    @include susy-breakpoint($bp-large, $susy) {
        display: inline;
        margin-bottom: 0;
    }
}

.content--aside {
    @include span(12 last);
    margin-bottom: spacing(large);

    @include susy-breakpoint($bp-medium, $susy) {
        @include span(4 at 5 last);
    }
}

.content--main {
    @include span(12 last);

    @include susy-breakpoint($bp-medium, $susy) {
        @include span(7 first);
    }
}

.footer--site {
    @include span(12);
    margin-top: spacing(x-large);
}

.about {
    text-align: center;

    @include susy-breakpoint($bp-large, $susy) {
        text-align: left;
    }
}

// ------------------------------
// #LISTING
// ------------------------------

// ------------------------------
// #CONVERSATION
// ------------------------------
