// ------------------------------
// textsfromjoe.com components

// ------------------------------
// #CONVERSATIONS
// ------------------------------
.conversations {

}

.wrapper--conversation {
    @extend %clear-last-child;
    z-index: z-index(back);
    margin: 0 0 spacing(xx-large) 0;
}

.conversation__link {
    @extend %link;
    color: palette(gray, base);
}

.conversation__title {
    @extend %a11y-hide;
}

.conversation__meta {
    border-top: rem(3) solid palette(gray, dark);
}

.conversation__date {
    @include transition(color timing(x-fast) ease-in-out);
    position: relative;
    top: -(rem(11));
    margin-bottom: spacing(small);
    margin-left: spacing(mid-small);
    padding: spacing(small);
    font-size: modular-scale(-2);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    background: palette(gray, x-dark);
    color: palette(gray, base);
}

.conversation__date__date {
    margin-right: spacing(x-small);
}


// ------------------------------
// #MESSAGES
// ------------------------------
$message-triangle-size: rem(20);

%message {
    position: relative;
    margin: spacing(large) 0 spacing(x-large) 0;
    border-radius: spacing(x-small);
    padding: spacing(mid-small) spacing(base);
    background: palette(green, base);

    &:hover, &:focus, &:active {
        box-shadow: 0 2px 1px 0 palette(gray, black);
    }

    &:after {
        position: absolute;
        bottom: -($message-triangle-size);
        content: "";
        font-size: 0;
    }
}

.list--messages {
    @extend %reset-lists;
}

.message {
    @extend %message;
    @include transition(all timing(x-fast) ease-in-out);
    opacity: 0.80;
    background: palette(gray, base);
    font-size: modular-scale(1);
    letter-spacing: 0.025rem;
    color: palette(gray, white-t);

    // STATE: hover, focus, and active
    &:hover,
    &:focus,
    &:active {
        @include transform(scale(1.025));
        opacity: 1;

        &:before {
            color: palette(gray, light);
        }
    }

    &:before {
        @include transition(color timing(x-fast) ease-in-out);
        position: absolute;
        top: -(25px);
        left: spacing(base);
        content: "";
        font-size: modular-scale(-3);
        color: palette(gray, base);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: spacing(mid-large);
    }

    // content types
    p {
        @extend %clear-last-child;
    }
}

.message--joe {
    background: palette(green, base);

    // decorative triangle
    &:after {
        @include triangle(20px, palette(green, base), up-right);
        right: $message-triangle-size;
    }

    // visual metadata
    &:before {
        content: "Joe";
    }
}

.message--her {
    background: palette(pink, base);

    // decorative triangle
    &:after {
        @include triangle(20px, palette(pink, base), up-left);
        left: $message-triangle-size;
    }

    // visual metadata
    &:before {
        content: "Her";
    }
}

.message--him {
    background: palette(blue, base);

    // decorative triangle
    &:after {
        @include triangle(20px, palette(blue, base), up-left);
        left: $message-triangle-size;
    }

    // visual metadata
    &:before {
        content: "Him";
    }
}

// ------------------------------
// #CHROME
// ------------------------------
.header--site {

}

.header--site__title {
    margin-bottom: spacing(x-large);
    font-size: modular-scale(3);
    font-weight: font-weight(normal);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: palette(gray, white);

    a {
        color: palette(gray, white);
        padding: 0;
        border-bottom: 0;
    }
}

.header--site__tagline {
    margin-bottom: spacing(small);
    font-size: modular-scale(1);
    color: palette(gray, light);
}

.header--site__title__accent {
    @extend %message;
    background: palette(gray, white);
    color: palette(gray, black);

    &:after {
        @include triangle(20px, palette(gray, white), up-right);
        position: absolute;
        bottom: -($message-triangle-size);
        content: "";
        font-size: 0;
        right: $message-triangle-size;
    }
}

.footer--site {
    @extend %a11y-hide;
    border-top: rem(2) solid palette(gray, black);
    padding-top: spacing(base);
}

.footer--site__copyright {
    font-size: modular-scale(-1);
    color: palette(gray, base);
}

// ------------------------------
// #META
// ------------------------------
.about {
    margin-top: spacing(mid-large);
    border-top: rem(2) solid palette(gray, black);
    padding-top: spacing(base);
}

.about__title {
    font-size: modular-scale(-1);
    font-weight: font-weight(normal);
    color: palette(gray, white);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

.about__copy {
    font-size: modular-scale(-1);
    color: palette(gray, light);
}

